<template>
  <div class="mobileCurtainOne">
    <mHead :name="'窗帘电机'" :add="'/mobile/ProCurtain'" :adds="'/mobile/ProCurtainParam'" :color="true" />
    <div class="banner">
      <div class="bannerBox">
        <div class="title">窗帘电机</div>
        <div class="text">性能强劲，体验豪华</div>
        <div class="ul">
          <div class="li">一键开合</div>
          <div class="li">智能联动</div>
          <div class="li">支持定制</div>
          <div class="li">语音控制</div>
        </div>
      </div>
    </div>
    <div class="sec1">
      <div class="title">每一次技术革新，只为用户体验</div>
      <div class="secBox">
        <img src="../../assets/mobile/product/curtainOne1.png" class="secImg" />
        <div class="tit">静音设计，无声运行</div>
        <div class="text">全新的电机和静音技术，</div>
        <div class="text texts">使电机在运行时的噪音控制在22分贝以下，</div>
        <div class="text texts">窗帘开合，仿佛无声</div>
      </div>
      <div class="secBox">
        <img src="../../assets/mobile/product/curtainOne2.png" class="secImg" />
        <div class="tit">信号强劲，控制于无形之中</div>
        <div class="text">全新的自研无线解决方案，</div>
        <div class="text texts">使信号覆盖范围与稳定性大大增强，</div>
        <div class="text texts">就算是强干扰环境也不再话下</div>
      </div>
      <div class="secBox">
        <img src="../../assets/mobile/product/curtainOne3.png" class="secImg" />
        <div class="tit">你的风格，你来定</div>
        <div class="text">支持定制，还可适应不同的的窗帘和轨道，</div>
        <div class="text texts">高端体验，为你打造</div>
        <div class="data">
          <img src="../../assets/mobile/dataImg.png" class="dataImg" />
        </div>
      </div>
    </div>
    <div class="sec2">
      <div class="title">用户体验的提升，</div>
      <div class="title titles">源自于每一个瞬间的人性</div>
      <div class="text">智能联动BOT智能照明，智能影音等系统，</div>
      <div class="text texts">定制场景，定制体验</div>
      <div class="secBox">
        <img src="../../assets/mobile/product/curtainOne4.png" class="secImg" />
        <div class="tit">智能联动，打造入住新体验</div>
        <div class="txt">刷卡进门，窗帘自动缓缓推开，</div>
        <div class="txt txts">灯光系统自动亮起，背景音乐轻柔响起。</div>
      </div>
      <div class="secBox">
        <img src="../../assets/mobile/product/curtainOne5.png" class="secImg" />
        <div class="tit">晚安好梦，独享五星级的睡眠环境</div>
        <div class="txt">朦胧睡意，无线遥控一键关灯，</div>
        <div class="txt txts">窗帘自动缓缓关闭，空调自动调整。</div>
        <div class="txt txts">打造睡眠环境，从未如此轻松。</div>
      </div>
    </div>
    <div class="sec3">
      <div class="tit">控制，不止一种方式</div>
      <div class="ul">
        <div class="li">
          <img src="../../assets/mobile/product/curtainOneIcon1.png" class="liImg" />
          <div class="liName">语音指令，智能联动</div>
        </div>
        <div class="li">
          <img src="../../assets/mobile/product/curtainOneIcon2.png" class="liImg" />
          <div class="liName">手机控制，免去繁琐</div>
        </div>
        <div class="li">
          <img src="../../assets/mobile/product/curtainOneIcon3.png" class="liImg" />
          <div class="liName">感应触碰，自动启动</div>
        </div>
        <div class="li">
          <img src="../../assets/mobile/product/curtainOneIcon4.png" class="liImg" />
          <div class="liName">智能组网，拉一边动两边</div>
        </div>
      </div>
    </div>
    <div class="sec4">
      <div class="title">简约设计，优雅无形</div>
      <div class="text">简约设计，</div>
      <div class="text texts">超静音窗帘电机可是适配不同种类的窗帘和窗纱款式，</div>
      <div class="text texts">搭配不同环境风格。</div>
      <div class="text texts">就算是弧形，异形轨道也不再话下</div>
      <div class="sec4Img"></div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  created() {}
};
</script>
<style scoped lang="less">
.mobileCurtainOne {
  background-color: #fff;
}
.banner {
  width: 100%;
  height: 8rem;
  color: #333333;
  background-image: url(../../assets/mobile/product/curtainOneBanner.png);
  background-size: 100% 100%;
  padding-top: 1rem;
  text-align: center;
  .bannerBox {
    width: 100%;
    height: 2.4rem;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.001) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0.001) 100%
    );
    .title {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      padding-top: 0.39rem;
    }
    .text {
      font-size: 0.3rem;
      line-height: 0.3rem;
      font-weight: 400;
      margin-top: 0.39rem;
    }
    .ul {
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      margin-top: 0.23rem;
      justify-content: center;
      .li {
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        border-right: 0.02rem solid #333;
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
          border-right: 0;
        }
      }
    }
  }
}
.sec1 {
  padding-top: 0.8rem;
  .title {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
    padding-bottom: 0.4rem;
  }
  .secBox {
    width: 100%;
    background-color: #f8f8f8;
    padding: 0.3rem;
    padding-bottom: 0;
    &:last-of-type{
      padding-bottom: 0.3rem;
    }
    display: flex;
    flex-direction: column;
    .secImg {
      width: 100%;
    }
    .tit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #ff9000;
      margin-top: 0.3rem;
    }
    .text {
      margin-top: 0.2rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
    }
    .texts {
      margin-top: 0.16rem;
    }
    .data {
      width: 6.45rem;
      margin: 0 auto;
      margin-top: 0.39rem;
      display: flex;
      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
.sec2 {
  padding-top: 0.8rem;
  color: #333333;
  .title {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .titles {
    margin-top: 0.24rem;
  }
  .text {
    margin-top: 0.4rem;
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
  }
  .texts {
    margin-top: 0.16rem;
    margin-bottom: 0.46rem;
  }
  .secBox {
    width: 100%;
    padding: 0 0.3rem;
    text-align: left;
    color: #333333;
    padding-bottom: 0.3rem;
    &:last-of-type {
      padding-bottom: 0;
    }
    .secImg {
      width: 100%;
    }
    .tit {
      font-size: 0.3rem;
      line-height: 0.3rem;
      font-weight: 800;
    }
    .txt {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      margin-top: 0.19rem;
    }
    .txts {
      margin-top: 0.16rem;
    }
  }
}
.sec3 {
  padding-top: 0.8rem;
  .tit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
  }
  .ul {
    padding-top: 0.6rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .li {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.6rem;
      &:nth-of-type(3),
      &:nth-of-type(4) {
        margin-bottom: 0;
      }
      .liImg {
        width: 1.35rem;
        height: 1.35rem;
      }
      .liName {
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #333333;
        margin-top: 0.4rem;
      }
    }
  }
}
.sec4 {
  padding-top: 0.8rem;
  color: #333333;
  .title {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .text {
    font-size: 0.24rem;
    line-height: 0.24rem;
    font-weight: 400;
    margin-top: 0.39rem;
  }
  .texts {
    margin-top: 0.16rem;
  }
  .sec4Img {
    width: 100%;
    height: 3.5rem;
    background-image: url(../../assets/mobile/product/curtainOne6.png);
    background-size: 100% 100%;
    margin-top: 0.3rem;
  }
}
</style>